
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css'
import logo from "../img/logo-timeline.svg";
import bulb from "../img/bulb.svg";
import cogs from "../img/cogs.svg";
import trademark from "../img/trademark.svg";
import website from "../img/website.svg";
import domain from "../img/domain.svg";
import patent from "../img/patent.svg";
import finalProduct from "../img/final-product.svg";
import company from "../img/company.svg";
import production from "../img/production.svg";
import delivery from "../img/delivery.svg";
import flag from "../img/flag.svg";
import AboutButton from '../components/AboutButton'
import AboutButton2 from '../components/AboutButton2'
import AboutButton3 from '../components/AboutButton3'
import AboutButton4 from '../components/AboutButton4'


export const AboutPageTemplate = ({ image, title, content, contentComponent, }) => {
  const PageContent = contentComponent || Content;
  const boxShadowStyle =
    "0 0 0 4px #F57E4D, inset 0 2px 0 rgba(0,0,0,.08), 0 3px 0 4px rgba(0,0,0,.05)";

  return (
      <div>
        <div 
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`
      }}
    > 
        <div className="content">
        <h2
        className="has-text-weight-bold is-size-1"
          style={{
            boxShadow:
              'rgb(245, 126, 77) 0.5rem 0px 0px, rgb(245, 126, 77) -0.5rem 0px 0px',
            backgroundColor: 'rgb(245, 126, 77)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
      >
        About us
      </h2>
    </div>
    </div>
      <section className="section section--gradient">
      <section className="aboutSiteSection">
         
      <div className="container">
        <div className="columns">
          <div className="column is-12 is-offset-1">
            <div className="section">
              
              <h3 className="title is-size-2 has-text-weight-semibold ">
                Neederland's Mission
              </h3>
                <div className="content">
                <div className="tile is-7 " >
                <h4 className="subtitle">
                NL delivered! We promise to deliver NEEDED products and services developed with the highest quality components by our top skilled engineers. Welcome to our LAND.
                
                </h4>
                </div>
                </div>
              <br/>
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              
              <PageContent className="content" content={content} />
              <div className="aboutContainer">
              <AboutButton/><AboutButton2/><br/>
              <AboutButton3/><AboutButton4/>
              </div>
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light" style={{marginTop:'30px'}}>
                Our story
              </h2>
            </div>
          </div>
        </div>
      </div>
      <VerticalTimeline className="vertical-timeline-custom-line" animate={false}>
        <VerticalTimelineElement
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={logo} alt="Logo" />
            </span>
          }
        />

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(71, 80, 98)', color: '#fff', boxShadow:'none', textShadow: '-1px 1px 2px rgb(71, 80, 98), 1px 1px 2px rgb(71, 80, 98), 1px -1px 2px rgb(71, 80, 98), -1px -1px 2px rgb(71, 80, 98)' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 126, 77)' }}
          
          date="June 2020"
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={bulb} alt="Idea" />
            </span>
          }
        >
          <h3 className="vertical-timeline-element-title">Idea</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(71, 80, 98)', color: '#fff', boxShadow:'none', textShadow: '-1px 1px 2px rgb(71, 80, 98), 1px 1px 2px rgb(71, 80, 98), 1px -1px 2px rgb(71, 80, 98), -1px -1px 2px rgb(71, 80, 98)' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 126, 77)' }}
          
          date="June 2020"
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={domain} alt="Domain" />
            </span>
          }
        >
          <h3 className="vertical-timeline-element-title">Domain</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(71, 80, 98)', color: '#fff', boxShadow:'none', textShadow: '-1px 1px 2px rgb(71, 80, 98), 1px 1px 2px rgb(71, 80, 98), 1px -1px 2px rgb(71, 80, 98), -1px -1px 2px rgb(71, 80, 98)' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 126, 77)' }}
          
          date="July 2020"
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={company} alt="Company Incorporation" />
            </span>
          }
        >
          <h3 className="vertical-timeline-element-title">
            Company incorporation
          </h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(71, 80, 98)', color: '#fff', boxShadow:'none', textShadow: '-1px 1px 2px rgb(71, 80, 98), 1px 1px 2px rgb(71, 80, 98), 1px -1px 2px rgb(71, 80, 98), -1px -1px 2px rgb(71, 80, 98)' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 126, 77)' }}
          
          date="July 2020"
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={cogs} alt="Prototype" />
            </span>
          }
        >
          <h3 className="vertical-timeline-element-title">Prototype</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(71, 80, 98)', color: '#fff', boxShadow:'none', textShadow: '-1px 1px 2px rgb(71, 80, 98), 1px 1px 2px rgb(71, 80, 98), 1px -1px 2px rgb(71, 80, 98), -1px -1px 2px rgb(71, 80, 98)' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 126, 77)' }}
          
          date="August 2020"
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={trademark} alt="Trademark" />
            </span>
          }
        >
          <h3 className="vertical-timeline-element-title">Trademark</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(71, 80, 98)', color: '#fff', boxShadow:'none', textShadow: '-1px 1px 2px rgb(71, 80, 98), 1px 1px 2px rgb(71, 80, 98), 1px -1px 2px rgb(71, 80, 98), -1px -1px 2px rgb(71, 80, 98)' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 126, 77)' }}
          
          date="October 2020"
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={patent} alt="Patent" />
            </span>
          }
        >
          <h3 className="vertical-timeline-element-title">Patent</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(71, 80, 98)', color: '#fff', boxShadow:'none', textShadow: '-1px 1px 2px rgb(71, 80, 98), 1px 1px 2px rgb(71, 80, 98), 1px -1px 2px rgb(71, 80, 98), -1px -1px 2px rgb(71, 80, 98)' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 126, 77)' }}
          
          date="October 2020"
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={website} alt="Website" />
            </span>
          }
        >
          <h3 className="vertical-timeline-element-title">Website</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(71, 80, 98)', color: '#fff', boxShadow:'none', textShadow: '-1px 1px 2px rgb(71, 80, 98), 1px 1px 2px rgb(71, 80, 98), 1px -1px 2px rgb(71, 80, 98), -1px -1px 2px rgb(71, 80, 98)' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 126, 77)' }}
          
          date="December 2020"
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={finalProduct} alt="Final Product" />
            </span>
          }
        >
          <h3 className="vertical-timeline-element-title">Truestand - Final Product</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(71, 80, 98)', color: '#fff', boxShadow:'none', textShadow: '-1px 1px 2px rgb(71, 80, 98), 1px 1px 2px rgb(71, 80, 98), 1px -1px 2px rgb(71, 80, 98), -1px -1px 2px rgb(71, 80, 98)' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 126, 77)' }}
          
          date="March 2021"
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={production} alt="Production" />
            </span>
          }
        >
          <h3 className="vertical-timeline-element-title">Production start</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(71, 80, 98)', color: '#fff', boxShadow:'none', textShadow: '-1px 1px 2px rgb(71, 80, 98), 1px 1px 2px rgb(71, 80, 98), 1px -1px 2px rgb(71, 80, 98), -1px -1px 2px rgb(71, 80, 98)' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 126, 77)' }}
          
          date="April 2021"
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={delivery} alt="Delivery" />
            </span>
          }
        >
          <h3 className="vertical-timeline-element-title">Delivery</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: 'rgb(71, 80, 98)', color: '#fff', boxShadow:'none', textShadow: '-1px 1px 2px rgb(71, 80, 98), 1px 1px 2px rgb(71, 80, 98), 1px -1px 2px rgb(71, 80, 98), -1px -1px 2px rgb(71, 80, 98)' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(245, 126, 77)' }}
          
          date="TBA"
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={finalProduct} alt="Final Product" />
            </span>
          }
        >
          <h3 className="vertical-timeline-element-title">AudioSwitch - Final Product</h3>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          iconStyle={{
            boxShadow: boxShadowStyle,
            background: "#000",
            color: "#000",
          }}
          icon={
            <span className="img_logo">
              <img src={flag} alt="Flag" />
            </span>
          }
        />
      </VerticalTimeline>
    </section>
    </section>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <AboutPageTemplate
        image={frontmatter.image}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};



AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
