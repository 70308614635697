import React from "react"

const AboutButton = () => {
  

  
  return (

      
      <button style={{
                height: '250px',
                width: '250px',
                backgroundColor: "#f57e4d",
                whiteSpace: 'normal',
                marginRight: '25px',
                marginBottom: '25px',
                transition: 'background-color 0.75s',
                fontSize: "3.6em",
                userSelect: "none",
                boxShadow: "none",
                outline: "none"
                  }}
                 id="aboutButton" type="submit" className="button is-link" onClick={function(){
        if (document.getElementById("aboutButton").firstChild.data === "Nifty") {
            document.getElementById("aboutButton").style.backgroundColor="#13CFB6";
            document.getElementById("aboutButton").style.fontSize = "1em";
            document.getElementById("aboutButton").firstChild.data = 
                "Our go-to word during the product life-cycle. The fact that no one been there does not mean that it is not there. Great goods need well-thought internal and external design.";

            
        } else {
            document.getElementById("aboutButton").style.backgroundColor="#f57e4d"
            document.getElementById("aboutButton").firstChild.data = "Nifty";
            document.getElementById("aboutButton").style.fontSize = "3.6em";

      
    }}}>
        Nifty
      </button>


 
)}

export default AboutButton