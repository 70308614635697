import React from "react"

const aboutButton2 = () => {
  

  
  return (

      
      <button style={{
                height: '250px',
                width: '250px',
                backgroundColor: "#f57e4d",
                whiteSpace: 'normal',
                marginBottom: '25px',
                transition: 'background-color 0.75s',
                fontSize: "3.6em",
                userSelect: "none",
                boxShadow: "none",
                outline: "none"
                  }}
                 id="aboutButton2" type="submit" className="button is-link" onClick={function(){
        if (document.getElementById("aboutButton2").firstChild.data === "Novel") {
            document.getElementById("aboutButton2").style.backgroundColor="#13CFB6";
            document.getElementById("aboutButton2").style.fontSize = "1em";
            document.getElementById("aboutButton2").firstChild.data = 
                "Enhancing something that already exists or developing an invention from scratch. That is what keeps us going.";

            
        } else {
            document.getElementById("aboutButton2").style.backgroundColor="#f57e4d"
            document.getElementById("aboutButton2").firstChild.data = "Novel";
            document.getElementById("aboutButton2").style.fontSize = "3.6em";

      
    }}}>
        Novel
      </button>


 
)}

export default aboutButton2