import React from "react"

const aboutButton4 = () => {
    
  
  return (

      
      <button style={{
                height: '250px',
                width: '250px',
                backgroundColor: "#f57e4d",
                whiteSpace: 'normal',
                marginBottom: '25px',
                transition: 'background-color 0.75s',
                fontSize: "3.6em",
                userSelect: "none",
                boxShadow: "none",
                outline: "none"
                  }}
                 id="aboutButton4" type="submit" className="button is-link" onClick={function(){
        if (document.getElementById("aboutButton4").firstChild.data === "Needed") {
            document.getElementById("aboutButton4").style.backgroundColor="#13CFB6";
            document.getElementById("aboutButton4").style.fontSize = "1em";
            document.getElementById("aboutButton4").firstChild.data = 
                "We are great observers and listeners. To You, our users and friends. In this way, we can fill niches."
            
        } else {
            document.getElementById("aboutButton4").style.backgroundColor="#f57e4d"
            document.getElementById("aboutButton4").firstChild.data = "Needed";
            document.getElementById("aboutButton4").style.fontSize = "3.6em";

      
    }}}>
        Needed
      </button>


 
)}

export default aboutButton4