import React from "react"

const aboutButton3 = () => {
  

  
  return (

      
      <button style={{
                height: '250px',
                width: '250px',
                backgroundColor: "#f57e4d",
                whiteSpace: 'normal',
                marginRight: '25px',
                marginBottom: '25px',
                transition: 'background-color 0.75s',
                fontSize: "3.6em",
                userSelect: "none",
                boxShadow: "none",
                outline: "none"
                  }}
                 id="aboutButton3" type="submit" className="button is-link" onClick={function(){
        if (document.getElementById("aboutButton3").firstChild.data === "Noble") {
            document.getElementById("aboutButton3").style.backgroundColor="#13CFB6";
            document.getElementById("aboutButton3").style.fontSize = "1em";
            document.getElementById("aboutButton3").firstChild.data = 
                "Breakthrough ideas, sustainable execution. Say thanks to our high morals and ideals.";

            
        } else {
            document.getElementById("aboutButton3").style.backgroundColor="#f57e4d"
            document.getElementById("aboutButton3").firstChild.data = "Noble";
            document.getElementById("aboutButton3").style.fontSize = "3.6em";

      
    }}}>
        Noble
      </button>


 
)}

export default aboutButton3